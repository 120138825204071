import React from 'react';
import MyHeader from "../../components/header/MyHeader";
import MyFooter from "../../components/footer/MyFooter";
import PORTIntro from "../PORTIntro";
import  cl from '../banana/Banana.module.css';
import banana from '../../portfolio/profilo1.webp'
import MyName from "../../components/name/MyName";

const Work4 = () => {
    return (
        <div style={{
            overflow: 'hidden'
        }}>
            <MyHeader/>
            <PORTIntro/>
            <div className={cl.feedbackContainer}>
                <MyName name={'Отзыв'}/>
                <div className={cl.feedback}>
                    Все отлично. Выполнил в срок, качественнно. Всегда на связи, не приходилось долго ждать ответа. Остались только положительные впечатления!
                </div>
            </div>
            <div className={cl.container}>
                <img src={banana} alt=""/>
            </div>
            <MyFooter/>
        </div>
    );
};

export default Work4;