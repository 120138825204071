import React from 'react';
import  cl from './PortMain.module.css'
import PortPart from "./elements/PortPart";
import StackPart from "./elements/StackPart";
const PortMain = () => {
    return (
        <div className={cl.mainCon}>
            <PortPart/>
            <div className={cl.container}>
                <StackPart/>
            </div>
        </div>
    );
};

export default PortMain;