import React from 'react';
import MyHeader from "../../components/header/MyHeader";
import MyFooter from "../../components/footer/MyFooter";
import PORTIntro from "../PORTIntro";
import  cl from '../banana/Banana.module.css';
import shti from '../../portfolio/Smaulik5.webp'
import MyName from "../../components/name/MyName";

const Work3 = () => {
    return (
        <div style={{
            overflow: 'hidden'
        }}>
            <MyHeader/>
            <PORTIntro/>
            <div className={cl.feedbackContainer}>
                <MyName name={'Отзыв'}/>
                <div className={cl.feedback}>
                    Задача была создать современный и удобный для пользователя лендинг!

                    Исполнитель полностью справился с этой задачей!  В работе были учтены все мои запросы, как заказчика.  Результат-качественный, стильный лендинг, с различным функционалом.

                    Спасибо большое за работу!
                </div>
            </div>
            <div className={cl.container}>
                <img src={shti} alt=""/>
            </div>
            <MyFooter/>
        </div>
    );
};

export default Work3;