import {useState, useEffect} from 'react';
import React from 'react';
import MyHeader from "../components/header/MyHeader";
import IntroAbout from "../components/aboutPage/introAbout/IntroAbout";
import AboutMission from "../components/aboutPage/mission/AboutMission";
import AboutTeam from "../components/aboutPage/team/AboutTeam";
import MyProcess from "../components/process/MyProcess";
import MyFooter from "../components/footer/MyFooter";
import AboutStack from "../components/aboutPage/stack/AboutStack";
import MyComponent from "../ui components/MyComponent";
import MyComponentPhone from "../ui components/MyComponentPhone";
import CustomCursor from "../components/CustomCursor/CustomCursor";
import Loading from "../components/loading/Loading";
import {usePageLoaded} from "../components/usepageloaded/usePageLoaded";
import { Helmet } from "react-helmet";

const About = () => {
    const isLoaded = usePageLoaded();
const [width, setWidth] = useState(window.innerWidth);

      useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);
    
        // Добавляем обработчик события изменения размера окна
        window.addEventListener("resize", handleResize);
    
        // Удаляем обработчик при размонтировании компонента
        return () => window.removeEventListener("resize", handleResize);
      }, []);
    return (
        <>
            <Helmet>

                <title>Веб-дизайн и маркетинг | создание сайтов GoTem_web</title>

                <meta name="description" content="GoTem_web: создание сайтов под ключ недорого, быстро и качественно." />

                <link rel="canonical" href="https://gotem-web.ru/about" />

                <link rel="icon" type="image/png" href="../../public/favicon.png"/>
            </Helmet>
            {isLoaded ? (
                <div style={{
                    overflow: "hidden",
                    background: "#212121"
                }}>

                    <CustomCursor/>
                    <MyHeader/>
                    <IntroAbout/>
                    <AboutMission/>
                    <AboutTeam/>
                    {width<1025
                    ? <MyComponentPhone/>
                    :  <MyComponent/>
                    }
                    <MyProcess/>
                    <AboutStack/>
                    <MyFooter/>
                </div>
            ) : (
                <Loading/>
            )}
        </>
    );
};

export default About;