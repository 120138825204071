import React from 'react';
import MyHeader from "../components/header/MyHeader";
import MyFooter from "../components/footer/MyFooter";
import ServicesIntro from "../components/servicesPage/intro/ServicesIntro";
import MyProcess from "../components/process/MyProcess";
import MyTech from "../components/technologies/MyTech";
import ServicesTypes from "../components/servicesPage/typesInt/ServicesTypes";
import SerTypes from "../components/servicesPage/types/SerTypes";
import SerIn from "../components/servicesPage/serIn/SerIn";
import MyName from "../components/name/MyName";
import MyService from "../components/service/MyService";
import MyCalc from "../components/calc/MyCalc";
import CustomCursor from "../components/CustomCursor/CustomCursor";
import Loading from "../components/loading/Loading";
import { Helmet } from "react-helmet";
import {usePageLoaded} from "../components/usepageloaded/usePageLoaded";

const Services = () => {
    const isLoaded = usePageLoaded();

    return (
        <>
            <Helmet>

            <title>Создание сайтов под ключ | веб-студия GoTem_web</title>

            <meta name="description"
                  content="GoTem_web: веб-студия с лучшим предложением создания сайта под ключ"/>

            <link rel="canonical" href="https://gotem-web.ru/services"/>

            <link rel="icon" type="image/png" href="../../public/favicon.png"/>
        </Helmet>
            {isLoaded ? (
                <div style={{
                    overflow: "hidden",
                    background: "#212121"
                }}>

                    <CustomCursor/>
                    <MyHeader/>
                    <ServicesIntro/>
                    <MyProcess/>
                    <br/>
                    <br/>
                    <br/>
                    <MyTech/>
                    <MyName name={'Услуги'}/>
                    <SerIn/>
                    <MyService/>
                    <MyName name={'Калькулятор'}/>
                    <MyCalc/>
                    <MyName name={'Типы Сайтов'}/>
                    <ServicesTypes/>
                    <SerTypes/>
                    <MyFooter/>
                </div>
            ) : (
                <Loading/>
            )}
        </>

    );
};

export default Services;