import React, {useLayoutEffect} from 'react';
import MyHeader from "../components/header/MyHeader";
import MyFooter from "../components/footer/MyFooter";
import Begin from "../components/helpPage/vstup/Begin";
import MyName from "../components/name/MyName";
import Quest from "../components/helpPage/questions/Quest";
import Problem from "../components/helpPage/probl/Problem";
import MyCalc from "../components/calc/MyCalc";
import Links from "../components/helpPage/links/Links";
import MyTech from "../components/technologies/MyTech";
import PortMain from "../components/portPage/portMain/PortMain";
import CustomCursor from "../components/CustomCursor/CustomCursor";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import Loading from "../components/loading/Loading";
import {usePageLoaded} from "../components/usepageloaded/usePageLoaded";
import { Helmet } from "react-helmet";


const Help = () => {
    gsap.registerPlugin(ScrollTrigger)

    useLayoutEffect(() => {
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".links",
                scrub: true,
                start: "-40% bottom",
                end: "-40% 50%",
            }
        })

        tl.from(".links", {
            x: -1200,
        }).to(".links",
            {
                x:0,
            }
        );
    }, []);
    const isLoaded = usePageLoaded();


    return (
        <>
        <Helmet>
            <title>Разработка сайтов | веб-студия GoTem_web</title>

            <meta name="description" content="GoTem_web: разработка сайтов под ключ любой сложности" />

            <link rel="canonical" href="https://gotem-web.ru/help" />

            <link rel="icon" type="image/png" href="../../public/favicon.png"/>
        </Helmet>
            {isLoaded ? (
                <div style={{
                    overflow: "hidden",
                    background: "#212121"
                }}>
<CustomCursor/>
                    <MyHeader/>
                    <Begin/>
                    <MyName name={"FAQ"}/>
                    <Quest/>
                    <Problem/>
                    <MyCalc/>
                    <div className={'links'} style={{transition: "all, 1s"}}>
                        <Links/>
                    </div>
                    <PortMain/>
                    <MyTech/>
                    <MyFooter/>
                </div>
            ) : (
                <Loading/>
            )}
        </>

    );
};

export default Help;