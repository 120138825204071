import React, {useState} from 'react';
import cl from './Quest.module.css'
import MyQuest from "./MyQuest";

const FAQItem2 = ({ question, answer }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className={cl.container}>
            <div className={cl.faqItem}>
                <div className={cl.faqQuestion} onClick={toggleOpen}>
                    <span>{question}</span>
                    <button>
                        <div>
                            {isOpen ? "-" : "+"}
                        </div>
                    </button>
                </div>
                <div
                    className={cl.faqAnswer}
                    style={{
                        maxHeight: isOpen ? "180px" : "0",
                        overflow: "hidden",
                        transition: "1s ease",
                    }}
                >
                    <p>{answer}</p>
                </div>
            </div>
        </div>
    );
};


const Quest = () => {
    return (
        <div className={cl.container}>
            <div className={'frag'} style={{transition:"all, 1s"}}>
                <FAQItem2 question={'Что такое веб-студия GoTem_web?'} answer={'GoTem_web – это профессиональная веб-студия, специализирующаяся на создании уникальных сайтов, дизайне и продвижении. Мы помогаем бизнесам выделяться в интернете и привлекать клиентов.'}/>
            </div>
            <div className={'frag1'} style={{transition:"all, 1s"}}>
                <FAQItem2 question={'Какие услуги предоставляет GoTem_web?'} answer={'Мы предлагаем полный спектр услуг: разработка веб-сайтов, создание уникальных дизайнов, SEO-продвижение, техподдержка и контент-менеджмент. Вам не нужно обращаться к нескольким провайдерам – мы всё сделаем за вас!'}/>
            </div>
            <div className={'frag2'} style={{transition:"all, 1s"}}>
                <FAQItem2 question={'Какова стоимость создания сайта в GoTem_web?'} answer={'Стоимость создания сайта зависит от ваших требований и сложности проекта. Мы предлагаем индивидуальные решения под каждый запрос. Свяжитесь с нами для получения бесплатной консультации.'}/>
            </div>
            <div className={'frag3'} style={{transition:"all, 1s"}}>
                <FAQItem2 question={'Как долго занимает разработка сайта?'} answer={'Время разработки сайта варьируется в зависимости от сложности проекта и ваших пожеланий, но обычно это занимает от 1 до 8 недель. Мы всегда стараемся работать быстро и эффективно, не снижая качества работы.'}/>
            </div>
            <div className={'frag4'} style={{transition:"all, 1s"}}>
                <FAQItem2 question={'Что включено в SEO-продвижение от GoTem_web?'} answer={'SEO-продвижение включает в себя анализ ключевых слов, оптимизацию контента, внутреннюю и внешнюю оптимизацию, улучшение структуры сайта, анализ конкурентов и регулярные отчёты о результатах.'}/>
            </div>
            <div className={'frag5'} style={{transition:"all, 1s"}}>
                <FAQItem2 question={'Могу ли я обратиться в GoTem_web только за дизайном сайта?'} answer={'Да, GoTem_web предлагает услуги по созданию уникального веб-дизайна отдельно. Мы создадим стильный и функциональный интерфейс, который подчеркнёт индивидуальность вашего бизнеса.'}/>
            </div>
            <div className={'frag6'} style={{transition:"all, 1s"}}>
                <FAQItem2 question={'Предоставляете ли вы услуги техподдержки после создания сайта?'} answer={'Да, мы предлагаем услуги техподдержки и обслуживания сайта после его создания. Это включает в себя обновления, мониторинг, устранение ошибок и консультации.'}/>
            </div>
            <div className={'frag7'} style={{transition:"all, 1s"}}>
                <FAQItem2 question={'Как GoTem_web помогает в продвижении сайтов?'} answer={'Мы используем проверенные стратегии локального SEO, чтобы ваш бизнес был виден для потенциальных клиентов. Это включает оптимизацию на Яндекс.Картах, создание качественного контента и увеличение вашей онлайн-видимости.'}/>
            </div>
            <div className={'frag8'} style={{transition:"all, 1s"}}>
                <FAQItem2 question={'Предоставляете ли вы услуги по контекстной рекламе?'} answer={'Да, мы предоставляем услуги по настройке и управлению контекстной рекламой в Яндекс.Директ и других рекламных платформах, чтобы помочь вам привлечь целевую аудиторию.'}/>
            </div>

        </div>
    );
};

export default Quest;