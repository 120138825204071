import {React, useLayoutEffect} from 'react';
import cl from '../PortMain.module.css'
import banana from '../../../../portfolio/Smaulik1.2.webp'
import credit from '../../../../portfolio/sammy4.2.webp'
import future from '../../../../portfolio/Smaulik5.2.webp'
import dagestan from '../../../../portfolio/profilo1.2.webp'
import homes from '../../../../portfolio/Smaulik6.2.webp'
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

const PortPart = () => {

    return (
            <div className={cl.casesBlock}>
                <div className={cl.first}>
                    <a href="/work4" target='_blank' className={cl.work1}>
                        <img src={dagestan} alt=""/>
                    </a>
                    <a href="/work2" target='_blank' className={cl.work2}>
                        <img src={credit} alt=""/>
                    </a>
                </div>

                <a href="/work1" target='_blank' className={cl.work3}>
                    <img src={banana} alt=""/>
                </a>
                <div className={cl.second}>
                    <a href="/work5" target='_blank' className={cl.work4}>
                        <img src={homes} alt=""/>
                    </a>
                    <a href="/work3" target='_blank' className={cl.work5}>
                        <img src={future} alt=""/>
                    </a>
                </div>
            </div>
    );
};

export default PortPart;