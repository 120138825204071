import React, {useState} from 'react';
import cl from './MyProcess.module.css'
import first from '../../images/first.webp'
import contract from '../../images/contract.webp'
import design from '../../images/desingn.webp'
import front from '../../images/front.webp'
import back from '../../images/backend.webp'
import presentation from '../../images/presentation.webp'
import hosting from '../../images/hosting.webp'
import handshake from '../../images/handshake.webp'

const MyProcess = () => {

    const [view1, setView1] = useState('grid');
        const [view2, setView2] = useState('none');
        const [view3, setView3] = useState('none');
        const [view4, setView4] = useState('none');
        const [view5, setView5] = useState('none');
        const [view6, setView6] = useState('none');
        const [view7, setView7] = useState('none');
        const [view8, setView8] = useState('none');
        function item1() {
            setView1('grid')
            setView2('none')
            setView3('none')
            setView4('none')
            setView5('none')
            setView6('none')
            setView7('none')
            setView8('none')
        }
        function item2() {
            setView1('none')
            setView2('grid')
            setView3('none')
            setView4('none')
            setView5('none')
            setView6('none')
            setView7('none')
            setView8('none')
        }
        function item3() {
            setView1('none')
            setView2('none')
            setView3('grid')
            setView4('none')
            setView5('none')
            setView6('none')
            setView7('none')
            setView8('none')
        }
        function item4() {
            setView1('none')
            setView2('none')
            setView3('none')
            setView4('grid')
            setView5('none')
            setView6('none')
            setView7('none')
            setView8('none')
        }
        function item5() {
            setView1('none')
            setView2('none')
            setView3('none')
            setView4('none')
            setView5('grid')
            setView6('none')
            setView7('none')
            setView8('none')
        }
        function item6() {
            setView1('none')
            setView2('none')
            setView3('none')
            setView4('none')
            setView5('none')
            setView6('grid')
            setView7('none')
            setView8('none')
        }
        function item7() {
            setView1('none')
            setView2('none')
            setView3('none')
            setView4('none')
            setView5('none')
            setView6('none')
            setView7('grid')
            setView8('none')
        }
        function item8() {
            setView1('none')
            setView2('none')
            setView3('none')
            setView4('none')
            setView5('none')
            setView6('none')
            setView7('none')
            setView8('grid')
        }

    return (
        <div className={cl.process}>
                <div className={cl.leftProc}>
                    <div>
                        <div onClick={item1} style={{background: `${view1 === 'grid' ? '#4f2981' : '#5d5d5d'}`, color: `${view1 === 'grid' ? '#fc7c33' : '#212121'}`}}>КОНСУЛЬТАЦИЯ</div>
                        <div onClick={item2} style={{background: `${view2 === 'grid' ? '#4f2981' : '#5d5d5d'}`, color: `${view2 === 'grid' ? '#fc7c33' : '#212121'}`}}>БУМАЖНЫЕ МОМЕНТЫ</div>
                        <div onClick={item3} style={{background: `${view3 === 'grid' ? '#4f2981' : '#5d5d5d'}`, color: `${view3 === 'grid' ? '#fc7c33' : '#212121'}`}}>ДИЗАЙН</div>
                        <div onClick={item4} style={{background: `${view4 === 'grid' ? '#4f2981' : '#5d5d5d'}`, color: `${view4 === 'grid' ? '#fc7c33' : '#212121'}`}}>FRONTEND</div>
                        <div onClick={item5} style={{background: `${view5 === 'grid' ? '#4f2981' : '#5d5d5d'}`, color: `${view5 === 'grid' ? '#fc7c33' : '#212121'}`}}>BACKEND</div>
                        <div onClick={item6} style={{background: `${view6 === 'grid' ? '#4f2981' : '#5d5d5d'}`, color: `${view6 === 'grid' ? '#fc7c33' : '#212121'}`}}>ПРЕЗЕНТАЦИЯ</div>
                        <div onClick={item7} style={{background: `${view7 === 'grid' ? '#4f2981' : '#5d5d5d'}`, color: `${view7 === 'grid' ? '#fc7c33' : '#212121'}`}}>ХОСТИНГ</div>
                        <div onClick={item8} style={{background: `${view8 === 'grid' ? '#4f2981' : '#5d5d5d'}`, color: `${view8 === 'grid' ? '#fc7c33' : '#212121'}`}}>СОТРУДНИЧЕСТВО</div>
                    </div>
                </div>
                <div className={cl.rightProc}>
                    <div style={{display: view1}} className={cl.item}>
                        <div>
                            <img src={first} alt=""/>
                        </div>
                        <div>
                            <h3>
                                Бесплатная консультация: первый шаг к идеальному сайту
                            </h3>
                            <p>
                                Здравствуйте! Давайте выберем проект мечты вместе. Представьте нас как навигаторов в
                                мире веб-разработки. И не волнуйтесь, мы не заставим вас покупать карту или компас!
                                Хотите сайт, скрипт или тг-бот? Мы тут как тут.
                            </p>
                        </div>
                    </div >
                    <div style={{display: view2}} className={cl.item}>
                        <div>
                            <img src={contract} alt=""/>
                        </div>
                        <div>
                            <h3>
                                Бумажные моменты — оформляем без головной боли
                            </h3>
                            <p>
                                Время — деньги, поэтому мы определяем бюджет, сроки, обсуждаем SEO-волшебство и контекстную рекламу. Не переживайте, это как считать до трех. Пакет «Шагать легко», и кстати, получаем первую часть оплаты.
                            </p>
                        </div>
                    </div>
                    <div style={{display: view3}} className={cl.item}>
                        <div>
                            <img src={design} alt=""/>
                        </div>
                        <div>
                            <h3>
                                Дизайн — искусство превращать идеи в красоту
                            </h3>
                            <p>
                                Наши дизайнеры — настоящие художники. Создадим сайт, который будет радовать глаз. Какие анимации захочется увидеть? Уверяем, никаких фокусов с исчезновением текста. Мы согласуем с вами каждую деталь.
                            </p>
                        </div>
                    </div>
                    <div style={{display: view4}} className={cl.item}>
                        <div>
                            <img src={front} alt=""/>
                        </div>
                        <div>
                            <h3>
                                FrontEnd — оживляем ваш сайт
                            </h3>
                            <p>
                                Здесь начинается магия: разрабатываем интерфейс, воплотим в жизнь ваш дизайн. Как говорится, снаружи конфетка — так и хочется попробовать!
                            </p>
                        </div>
                    </div>
                    <div style={{display: view5}} className={cl.item}>
                        <div>
                            <img src={back} alt=""/>
                        </div>
                        <div>
                            <h3>
                                BackEnd — приводим сайт в работу
                            </h3>
                            <p>
                                Теперь нас ждет работа за кулисами. Прорабатываем все внутренние механизмы сайта. Ваша мечта стучится в интернет-двери.
                            </p>
                        </div>
                    </div>
                    <div style={{display: view6}} className={cl.item}>
                        <div>
                            <img src={presentation} alt=""/>
                        </div>
                        <div>
                            <h3>
                                Финальные шаги — финишная прямая
                            </h3>
                            <p>
                                Встречайте презентацию вашего сайта! Реализуем последние пожелания (даже те, что пришли в 3 часа ночи). Получаем вторую часть оплаты и празднуем запуск с салютом (ну, почти).
                            </p>
                        </div>
                    </div>
                    <div style={{display: view7}} className={cl.item}>
                        <div>
                            <img src={hosting} alt=""/>
                        </div>
                        <div>
                            <h3>
                                Хостинг — выбираем «дом» для вашего сайта
                            </h3>
                            <p>
                                Наши эксперты предложат подходящий тариф и разместят сайт в сети, как лучший домик для нового друга. Поможем избежать капканов — ничего лишнего, только нужное!
                            </p>
                        </div>
                    </div>
                    <div style={{display: view8}} className={cl.item}>
                        <div>
                            <img src={handshake} alt=""/>
                        </div>
                        <div>
                            <h3>
                                Сотрудничество — дружба, проверенная временем
                            </h3>
                            <p>
                                Мы — ваши долгосрочные друзья в мире IT. Регулярно поддерживаем, дорабатываем сайт, добавляем функции и продвигаем его на просторах сети. Давайте продвигаться к звездам вместе!
                            </p>
                        </div>
                    </div>
                </div>
            </div>

    );
};

export default MyProcess;