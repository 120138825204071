import React, { useState } from "react";
import cl from './FAQitemPhone.module.css'

// Компонент для одного вопроса и ответа
const FAQItem = ({ question, answer }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className={cl.container}>
            <div className={cl.faqItem}>
                <div className={cl.faqQuestion} onClick={toggleOpen}>
                    <span>{question}</span>
                    <button>
                        <div>
                            {isOpen ? "-" : "+"}
                        </div>
                    </button>
                </div>
                <div
                    className={cl.faqAnswer}
                    style={{
                        maxHeight: isOpen ? "440px" : "0",
                        overflow: "hidden",
                        transition: "1s ease",
                    }}
                >
                    <p>{answer}</p>
                </div>
            </div>
        </div>
    );
};

// Основной компонент для списка FAQ
const FAQ = () => {
    const faqList = [
        {
            question: "Почему такие несерьезные тексты на сайте?",
            answer: "Мы считаем, что у каждый бизнесмен уже устал разбираться в сложных юридических словах и прочем нагружающем мозг мусоре. Среди всех веб-студий наш сайт отличается не только своей красотой, но и легкостью понимания для пользователей, что помогает нам выстроить дружелюбную атмосферу общения с ними. Также мы демонстрируем наше умение погружаться абсолютно во все сферы жизнедеятельности, чтобы сделать Ваш сайт реально уникальным.",
        },
        {
            question: "Реальные ли факты использованы на странице?",
            answer: "Да, абсолютно вся информация, что представлена на нашем сайте — правдивая. Единственное, что может быть не точным — это количество чашек кофе, выпитых нашими сотрудниками. К сожалению они не все знают точное их количество. Также если что, отзывы были представлены шуточными.",
        },
        {
            question: "Реально ли вы можете сделать все?",
            answer: "Да, если у Вас есть какой-нибудь нестандартный проект, то лучшим вашим решение будет обращение к нам. Мы обращаемся к огромному количеству профессионалов из разных сфер, чтобы реализовать наши различные проекты, так что мы можем воплотить практически все ваши идеи.",
        },
        {
            question: "Стоимость проекта, его сроки выполнения и прочее",
            answer: "Тут как и все другие веб-студии, мы не можем сразу сказать Вам эту информацию по проекту, но для стоимости сайта Вы можете обратиться к нашему калькулятору и узнать её за 45 СЕКУНД, а для сроков, вы можете БЕСПЛАТНО проконсультироваться с нашим менеджером ВООБЩЕ ПО ЛЮБОМУ ВОПРОСУ. ",
        },
        {
            question: "Почему стоит ВЫБРАТЬ ИМЕННО НАС?",
            answer: "Да, мы начинающая веб-студия и у нас не так много грандиозных проектов, этим мы отличаемся от лидирующих веб-студий СНГ. Но от них мы взяли СУПЕР-КАЧЕСТВЕННОЕ выполнение работ и реально ПРОФЕССИОНАЛЬНЫЕ и КРАСИВЫЕ сайты, чем мы стали отличаться от бюджетных веб-студий. А от них, как раз, мы взяли очень БЮДЖЕТНЫЕ ЦЕНЫ. В следствии этого мы являемся самым ЛУЧШИМ вашим вариантом для создания АБСОЛЮТНО ЛЮБЫХ ВЕБ-ПРОДУКТОВ, сочетая невероятное качество и низкие цены.",
        },
        // Добавьте больше вопросов и ответов по мере необходимости
    ];

    return (
        <div className="faq-container">
            {faqList.map((item, index) => (
                <FAQItem key={index} question={item.question} answer={item.answer} />
            ))}
        </div>
    );
};

export default FAQ;