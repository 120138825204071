import React from 'react';
import cl from './ServicesIntro.module.css'
import services from '../../../images/shest.png'
import { useRef, useEffect } from 'react';


const IntroAbout = () => {

    const blockRef = useRef(null);
    const canvasRef = useRef(null);

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');

        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight * 1.4;

        const fontSize = 20;
        const columns = Math.floor(canvas.width / fontSize);
        const drops = Array(columns).fill(1);

        ctx.font = `${fontSize}px monospace`;

        const frameRate = 50;


        const fadeGradient = ctx.createLinearGradient(0, 0, 0, canvas.height);
        fadeGradient.addColorStop(0, 'rgba(252, 124, 51, 0.05)'); // начало градиента
        fadeGradient.addColorStop(1, 'rgba(79, 41, 129, 0.05)');

        const drawMatrix = () => {
            // Вычисление нового альфа-значения
            ctx.fillStyle = fadeGradient;
            ctx.fillRect(0, 0, canvas.width, canvas.height);

            ctx.fillStyle = '#212121' // Оранжевый или фиолетовый
            drops.forEach((yPos, index) => {
                const text = String.fromCharCode(33 + Math.random() * 94);
                const xPos = index * fontSize;
                ctx.fillText(text, xPos, yPos * fontSize);

                if (yPos * fontSize > canvas.height && Math.random() > 0.975) {
                    drops[index] = 0;
                }

                drops[index]++;
            });
        };
        

        const intervalId = setInterval(drawMatrix, frameRate);


        return () => clearInterval(intervalId);
    }, []);

    const handleContextMenu = (event) => {
        event.preventDefault();
    };
    return (
        <div
        ref={blockRef}
        style={{
            zIndex: 2
        }}
   >
     <canvas ref={canvasRef} style={{width: '110vw', height: '140vh', zIndex: 99999999, marginTop: '2vh'}}/>  
    <div className={cl.container}>
            <div className={cl.left}>
                <h1 className={cl.name}>
                    <span>Р</span><span>А</span><span>З</span><span>Д</span><span>Е</span><span>Л</span>
                    <br/>
                    <span>У</span><span>С</span><span>Л</span><span>У</span><span>Г</span><span>И</span>
                </h1>
            </div>
            <div className={cl.right}>
                <img src={services} alt="механизм создание лендингов и интернет магазниов на заказ в Омске" onContextMenu={handleContextMenu} draggable={"false"}/>
            </div>
        </div>
   </div>
    );
};

export default IntroAbout;