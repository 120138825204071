import React from 'react';
import MyHeader from "../../components/header/MyHeader";
import MyFooter from "../../components/footer/MyFooter";
import PORTIntro from "../PORTIntro";
import  cl from './Banana.module.css';
import banana from '../../portfolio/Smaulik1.webp'

const Banana = () => {
    return (
        <div style={{
            overflow: 'hidden'
        }}>
            <MyHeader/>
            <PORTIntro/>
            <div className={cl.container}>
                <img src={banana} alt=""/>
            </div>
            <MyFooter/>
        </div>
    );
};

export default Banana;