import React, { useState, useEffect, useRef } from 'react';
import cl from "../pages/MainPhone.module.css";
import arm from "../images/arm.png";
import clock from "../images/clock.webp";
import rockets from "../images/rocket.webp";
import spy from "../images/spy.webp";
import chef from "../images/chef.webp";
import prize from "../images/prize.webp";
import headphones from "../images/headphones.webp";
import note from "../images/note.webp";
import {motion} from "framer-motion";

const ScrollOverlay = () => {
    const blockRef = useRef(null);
    const canvasRef = useRef(null);

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');

        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight * 4;

        const fontSize = 20;
        const columns = Math.floor(canvas.width / fontSize);
        const drops = Array(columns).fill(1);

        ctx.font = `${fontSize}px monospace`;

        const frameRate = 50;
        const frameRate1 = 80;  // обновляем 20 раз в секунду (1000ms / 50ms = 20fps)
        const frameRate2 = 100; // обновляем 20 раз в секунду (1000ms / 50ms = 20fps) // сколько прозрачности добавлять на каждом кадре


        const fadeGradient = ctx.createLinearGradient(0, 0, 0, canvas.height);
        fadeGradient.addColorStop(0, 'rgba(252, 124, 51, 0.05)'); // начало градиента
        fadeGradient.addColorStop(1, 'rgba(79, 41, 129, 0.05)');

        const drawMatrix = () => {
            // Вычисление нового альфа-значения
            ctx.fillStyle = fadeGradient;
            ctx.fillRect(0, 0, canvas.width, canvas.height);

            ctx.fillStyle = '#212121' // Оранжевый или фиолетовый
            drops.forEach((yPos, index) => {
                const text = String.fromCharCode(33 + Math.random() * 94);
                const xPos = index * fontSize;
                ctx.fillText(text, xPos, yPos * fontSize);

                if (yPos * fontSize > canvas.height && Math.random() > 0.975) {
                    drops[index] = 0;
                }

                drops[index]++;
            });
        };
        const drawMatrix1 = () => {
            // Вычисление нового альфа-значения
            ctx.fillStyle = fadeGradient;
            ctx.fillRect(0, 0, canvas.width, canvas.height);

            ctx.fillStyle = '#212121' // Оранжевый или фиолетовый
            drops.forEach((yPos, index) => {
                const text = String.fromCharCode(33 + Math.random() * 94);
                const xPos = index * fontSize;
                ctx.fillText(text, xPos, yPos * fontSize);

                if (yPos * fontSize > canvas.height && Math.random() > 0.975) {
                    drops[index] = 0;
                }

                drops[index]++;
            });
        };

        const drawMatrix2 = () => {
            // Вычисление нового альфа-значения
            ctx.fillStyle = fadeGradient;
            ctx.fillRect(0, 0, canvas.width, canvas.height);

            ctx.fillStyle = '#212121' // Оранжевый или фиолетовый
            drops.forEach((yPos, index) => {
                const text = String.fromCharCode(33 + Math.random() * 94);
                const xPos = index * fontSize;
                ctx.fillText(text, xPos, yPos * fontSize);

                if (yPos * fontSize > canvas.height && Math.random() > 0.975) {
                    drops[index] = 0;
                }

                drops[index]++;
            });
        };

        const intervalId = setInterval(setInterval(drawMatrix, frameRate), 5000);

        const intervalId1 = setInterval(setInterval(drawMatrix1, frameRate1), 10000);

        const intervalId2 = setInterval(drawMatrix2, frameRate2);

        return () => clearInterval(intervalId, intervalId1, intervalId2);
    }, []);

    return (
        <div>
            <div className={cl.advantages}
                 ref={blockRef}
                 style={{
                     zIndex: 2
                 }}
            >
                <div>
                            <div className={cl.reviews}>
                                <div
                                    className={cl.review}
                                >
                                    <div className={cl.leftReview}>
                                        <div className={cl.img}>
                                        <img src={clock} alt=""/>
                                        </div>
                                        
                                        <h3>Сайт со скоростью света!</h3>
                                    </div>
                                    <div className={cl.rightReview}>
                    
                                        <p>Создание сайтов под ключ настолько быстрое, что их запуск занимает меньше
                                            времени, чем ваше утреннее кофе. Делаем быстро и качественно!</p>
                                    </div>
                                </div>
                                <div
                                    className={cl.review}
                                >
                                    <div className={cl.leftReview}>
                                        <div className={cl.img}>
                                        <img src={rockets} alt=""/>
                                        </div>
                                        
                                        <h3>Космические технологии, но земные цены</h3>
                                    </div>
                                    <div className={cl.rightReview}>
                                        
                                        <p>Мы занимаемся разработкой сайтов под ключ, как NASA запускает ракеты, но у нас это недорого и быстро. Пока другие веб-студии считают звёзды на своих ценниках, мы делаем круто и доступно.</p>
                                    </div>
                                </div>
                                <div
                                    className={cl.review}
                                >
                                    <div className={cl.leftReview}>
                                    <div className={cl.img}>
                                    <img src={spy} alt=""/>
                                            </div>
                                       
                                        <h3>Технические Джеймсы Бонды</h3>
                                    </div>
                                    <div className={cl.rightReview}>
                                        
                                        <p>Как бы сложна ни была миссия: от создания Telegram ботов до мастерства в интерфейсах гигантских приложений — нашим спецагентам всё по плечу. Их миссия — качественная разработка веб-продуктов под ключ.</p>
                                    </div>
                                </div>
                                <div
                                    className={cl.review}
                                >
                                    <div className={cl.leftReview}>
                                    <div className={cl.img}>
                                    <img src={chef} alt=""/>
                                            </div>
                                        
                                        <h3>Ваши идеи — наш основной ингредиент</h3>
                                    </div>
                                    <div className={cl.rightReview}>
                                        
                                        <p>Мы готовим ваш веб-проект под ключ как шеф-повар в хорошем ресторане. Делаем все от чизбургеров до конфи из утиных грудок с эмульсией из персикового редукта. От несложных лендингов до крупнейших интернет-магазинов</p>
                                    </div>
                                </div>
                                <div
                                    className={cl.review}
                                >
                                    <div className={cl.leftReview}>
                                    <div className={cl.img}>
                                    <img src={prize} alt=""/>
                                            </div>
                                        
                                        <h3>Опыт, который нельзя купить на AliExpress</h3>
                                    </div>
                                    <div className={cl.rightReview}>
                                        
                                        <p>У нас работают ветераны разработки скриптов и создания сайтов! Они быстро и качественно превратят ваши идеи в цифровую реальность под ключ, и никто даже не заметит, как.</p>
                                    </div>
                                </div>
                                <div
                                    className={cl.review}
                                >
                                    <div className={cl.leftReview}>
                                    <div className={cl.img}>
                                    <img src={headphones} alt=""/>
                                            </div>
                                        
                                        <h3>Дружеская техническая поддержка 25/8</h3>
                                    </div>
                                    <div className={cl.rightReview}>
                                        
                                        <p>Мы всегда на связи и готовы помочь вам с любыми вопросами по созданию сайтов под ключ и их эксплуатации. С нами легко и приятно работать!</p>
                                    </div>
                                </div>
                                <div
                                    className={cl.review}
                                >
                                    <div className={cl.leftReview}>
                                    <div className={cl.img}>
                                    <img src={note} alt="" style={{paddingTop:'5vh'}}/>
                                            </div>
                                        
                                        <h3>Клиенты говорят, мы слушаем и исполняем!</h3>
                                    </div>
                                    <div className={cl.rightReview}>
                                        
                                        <p>Ваше мнение — это мелодия, под которую мы танцуем. Расскажите, что нужно улучшить, — и мы отправимся на следующий этап SEO-оптимизации и разработки вашего сайта, чтобы сделать вам по настоящему высокую конверсию.</p>
                                    </div>
                                </div>
                        </div>
                </div>
                <canvas ref={canvasRef} style={{width: '110vw', height: '460vh', zIndex: -4, marginTop: '-700vh'}}/>
            </div>
        </div>
    );
};

export default ScrollOverlay;
